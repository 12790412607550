import {
  Box,
  Button,
  Container,
  debounce,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ressetPassword } from "../../services/auth.service";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState<any>({});
  const [openSuccess, setSuccessOpen] = useState(false);
  const [openError, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,

    formState: { isValid, isDirty, errors },
    handleSubmit,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      new_password: "",
      confirm_password: "",
    },
  });

  const checksumPassword = async (event: any) => {
    const { new_password, confirm_password } = getValues();

    if (new_password !== confirm_password && confirm_password) {
      setError("confirm_password", {
        type: "custom",
        message: "*รหัสผ่านไม่ตรงกัน โปรดลองอีกครั้ง*",
      });
    } else {
      clearErrors("confirm_password");
    }
  };

  // handle
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorOpen(false);
    setErrorMessage("");
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  // end handle

  const debouncedConfirmPasswordHandler = useMemo(
    () => debounce(checksumPassword, 300),
    []
  );

  // func
  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      const { new_password } = values;
      const { username, identification_number, province_id, district_id } =
        state;

      await ressetPassword({
        username,
        identification_number,
        province_id,
        district_id,
        new_password,
      });

      handleSuccessOpen();
      setTimeout(() => {
        handleSuccessClose();
        navigate("/login");
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };
  // end func

  useEffect(() => {
    if (!location.state) {
      navigate(`/`);
      return;
    }

    setState(location.state);
  }, [location.state, navigate]);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingBottom: "100px",
        paddingTop: "36px",
      }}
    >
      <Box display="flex" justifyContent="center" mb="16px">
        <Box
          component="img"
          src="/images/logo.png"
          width="75px"
          height="100px"
        />
      </Box>

      <Container
        component="main"
        sx={{ maxWidth: "840px", zIndex: 1 }}
        maxWidth={false}
      >
        <Box
          boxShadow="0px 0px 6px rgba(0, 0, 0, 0.15)"
          display="flex"
          flexDirection="column"
          gap="24px"
          borderRadius="10px"
          sx={{
            background: "#FFFFFF",
          }}
          padding={{ xs: "40px 32px", sm: "40px 149px" }}
        >
          <Typography align="center" variant="h3" color="ktgGreen.main">
            <FormattedMessage id="resetPassword.enterNewPassword" />
          </Typography>

          <Typography align="center" variant="body1" color="common.black">
            <FormattedMessage id="resetPassword.hint" />
          </Typography>

          <FormControl>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                <FormattedMessage id="resetPassword.username" />
              </Typography>
            </FormLabel>
            <TextField
              sx={{ maxWidth: "280px", w: 1 }}
              color="lightGrey"
              fullWidth
              type="text"
              placeholder="ชื่อผู้ใช้"
              value={state.username}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                <FormattedMessage id="resetPassword.password" />
              </Typography>
            </FormLabel>
            <TextField
              sx={{ maxWidth: "280px", w: 1 }}
              color="lightGrey"
              fullWidth
              type="password"
              placeholder="รหัสผ่าน"
              {...register("new_password", {
                required: true,
                onChange: debouncedConfirmPasswordHandler,
                minLength: 6,
              })}
            />
          </FormControl>

          <FormControl>
            <FormLabel>
              <Typography variant="body1" color="common.black">
                <FormattedMessage id="resetPassword.confirmPassword" />
              </Typography>
            </FormLabel>
            <TextField
              sx={{ maxWidth: "280px", w: 1 }}
              color="lightGrey"
              fullWidth
              type="password"
              placeholder="ยืนยันรหัสผ่านอีกครั้ง"
              {...register("confirm_password", {
                required: true,
                onChange: debouncedConfirmPasswordHandler,
                minLength: 6,
              })}
              error={!!errors["confirm_password"]}
              helperText={
                errors["confirm_password"]
                  ? errors["confirm_password"].message
                  : ""
              }
            />
          </FormControl>

          <Box display="flex" justifyContent="center" gap="24px">
            <Button
              color="blue"
              variant="outlined"
              type="button"
              component={RouterLink}
              to="/login"
              sx={{ maxWidth: "234px", width: 1 }}
            >
              <Typography variant="subtitle2">
                <FormattedMessage id="common.back" />
              </Typography>
            </Button>

            <Button
              color="green"
              variant="contained"
              type="button"
              disabled={
                !isDirty ||
                !isValid ||
                Object.values(errors).length !== 0 ||
                loading
              }
              onClick={handleSubmit(onSubmitHandler)}
              sx={{ maxWidth: "234px", width: 1 }}
            >
              <Typography variant="subtitle2" color="white">
                <FormattedMessage id="common.confirm" />
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 343,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Typography align="center">
            <CheckCircleOutlineIcon sx={{ fontSize: 60 }} color="ktgGreen" />
          </Typography>

          <Typography
            align="center"
            variant="h4"
            color="ktgGreen.main"
            marginTop="15px"
            marginBottom="15px"
          >
            <FormattedMessage id="forgot.success" />
          </Typography>
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 343,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Typography align="center">
            <HighlightOffIcon sx={{ fontSize: 60, color: "#EB698F" }} />
          </Typography>

          <Typography
            align="center"
            variant="h4"
            color="#EB698F"
            marginTop="15px"
          >
            {errorMessage}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default ResetPassword;
