import {
  Box,
  Typography,
  Container,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Modal,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { tableCellClasses } from "@mui/material/TableCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/Download";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  boxStyle,
  containerStyle,
  modalStyle,
  pageStyle,
} from "../../../consts/styles";
import { getCourseProgressService } from "../../../services/course.service";
import LessonNotDoneModal from "../../../components/modals/course/LessonNotDoneModal";
import WaitingForAnswerModal from "../../../components/modals/course/WaitingForAnswerModal";
import dayjs from "dayjs";
import AssessmentSuccessModal from "../../../components/modals/course/AssessmentSuccessModal";
import AssessmentFailModal from "../../../components/modals/course/AssessmentFailModal";
import WarningModal from "../../../components/modals/WarningModal";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 100,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D9E2EB",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 100,
    backgroundColor: "#2FD25D",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.ktjBlue.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CourseDescriptionPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [courseId, setCourseId] = useState<number | null>(null);
  const [course, setCourse] = useState<any | null>(null);
  const [percent, setPercent] = useState<any>(0);
  const [messageWarnning, setMessageWarnning] = useState<string>("");

  const [openLessonNotDone, setLessonNotDoneOpen] = useState<boolean>(false);
  const [openWaitingForAnswer, setWaitingForAnswerOpen] =
    useState<boolean>(false);
  const [openAssessmentSuccess, setAssessmentSuccessOpen] =
    useState<boolean>(false);
  const [openAssessmentFail, setAssessmentFailOpen] = useState<boolean>(false);
  const [openWarnning, setWarnningOpen] = useState<boolean>(false);

  const handleLessonNotDoneOpen = () => setLessonNotDoneOpen(true);
  const handleLessonNotDoneClose = () => setLessonNotDoneOpen(false);
  const handleWaitingForAnswerOpen = () => setWaitingForAnswerOpen(true);
  const handleWaitingForAnswerClose = () => setWaitingForAnswerOpen(false);
  const handleAssessmentSuccessOpen = () => setAssessmentSuccessOpen(true);
  const handleAssessmentSuccessClose = () => setAssessmentSuccessOpen(false);
  const handleAssessmentFailOpen = () => setAssessmentFailOpen(true);
  const handleAssessmentFailClose = () => setAssessmentFailOpen(false);
  const handleWarnningOpen = () => setWarnningOpen(true);
  const handleWarnningClose = () => {
    setMessageWarnning("");
    setWarnningOpen(false);
  };

  const getCourseProgress = () => {
    if (courseId) {
      getCourseProgressService(courseId).then((resp) => {
        setCourse(resp.data);
        setPercent((resp.data.done_lesson * 100) / resp.data.course_lesson);
      });
    }
  };

  const navigateToLesson = () => {
    navigate("/course/lesson-overview", {
      state: {
        courseId,
      },
      replace: true,
    });
  };

  const navigateToCertificate = () => {
    if (!course.is_pass) {
      setMessageWarnning(
        `คุณยังไม่ผ่าน แบบประเมินหลักสูตร จึงยังไม่สามารถ ออกใบประกาศนียบัตร ได้คะ`
      );
      return;
    }
    navigate("/course/certificate", {
      state: { courseId, evaluatedDate: course.evaluated_date },
    });
  };

  const navigateToAssessment = () => {
    if (course.done_lesson !== course.course_lesson) {
      handleLessonNotDoneOpen();
      return;
    } else if (course.is_pass) {
      handleAssessmentSuccessOpen();
      return;
    } else if (
      !course.tested_date ||
      (course.tested_date &&
        course.evaluated_date &&
        !course.is_pass &&
        (dayjs(course.tested_date).isBefore(dayjs(course.evaluated_date)) ||
          dayjs(course.tested_date).isSame(dayjs(course.evaluated_date))) &&
        dayjs().diff(dayjs(course.evaluated_date), "day") > course.gap_time)
    ) {
      navigate("/course/assessment", {
        state: {
          course,
        },
        replace: true,
      });
      return;
    } else if (
      !course.evaluated_date ||
      dayjs(course.evaluated_date).isBefore(dayjs(course.tested_date))
    ) {
      handleWaitingForAnswerOpen();
      return;
    } else if (!course.is_pass) {
      handleAssessmentFailOpen();
      return;
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/home");
      return;
    }
    setCourseId(location.state.courseId);
  }, [location, navigate]);

  useEffect(getCourseProgress, [courseId]);

  useEffect(() => {
    if (messageWarnning) handleWarnningOpen();
  }, [messageWarnning]);

  return (
    <Box sx={pageStyle}>
      <Typography
        align="center"
        sx={{ typography: { xs: "h3", sm: "h1" } }}
        color="ktgGreen.main"
        zIndex={100}
      >
        {course?.title}
      </Typography>

      <Container
        component="main"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        maxWidth={false}
      >
        <Box sx={{ ...boxStyle, padding: "16px" }}>
          <Typography align="center" typography="h4" color="black.main">
            ผ่าน 3 ขั้นตอนง่าย ๆ
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            borderRadius="5px"
            sx={{
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
              cursor: "pointer",
            }}
            padding="16px"
            gap="16px"
            onClick={navigateToLesson}
          >
            <Box display="flex" gap="16px">
              <Box
                component="img"
                src="/course/enter-the-lesson.png"
                width={100}
                height={70}
              />

              <Box alignSelf="center">
                <Typography typography="h5" color="blue.main">
                  เข้าสู่บทเรียน
                </Typography>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box width={0.8}>
                <BorderLinearProgress variant="determinate" value={percent} />
              </Box>

              <Box display="flex" flexDirection="row">
                <Typography typography="h5" color="black.main">
                  {course?.done_lesson}
                </Typography>
                <Typography
                  alignSelf="end"
                  typography="body1"
                  color="cgrey.main"
                >
                  / {course?.course_lesson}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            borderRadius="5px"
            sx={{
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
              cursor: "pointer",
            }}
            padding="16px"
            gap="8px"
            onClick={navigateToAssessment}
          >
            <Box display="flex" gap="16px">
              <Box
                component="img"
                src="/course/assessment.png"
                width={100}
                height={70}
              />

              <Box alignSelf="center">
                <Typography typography="h5" color="blue.main">
                  สอบประเมิน หลักสูตร
                </Typography>
              </Box>
            </Box>

            {course?.done_lesson !== course?.course_lesson && (
              <Typography typography="body1" color="cgrey.main">
                กรุณาเข้าสู่บทเรียน
              </Typography>
            )}
          </Box>

          <Box
            display="flex"
            borderRadius="5px"
            sx={{
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
              cursor: "pointer",
            }}
            padding="16px"
            gap="16px"
            onClick={navigateToCertificate}
          >
            <Box
              component="img"
              src="/course/download-certificate.png"
              width={100}
              height={70}
            />

            <Box alignSelf="center">
              <Typography typography="h5" color="blue.main">
                ดาวน์โหลด ใบประกาศนียบัตร
              </Typography>
            </Box>
          </Box>

          <Accordion
            defaultExpanded={true}
            sx={{
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
              borderRadius: "10px !important",
              "&::before": {
                backgroundColor: "white",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              sx={{
                borderRadius: "5px !important",
                background:
                  "linear-gradient(94.1deg, rgba(4, 205, 133, 0.9) -20.26%, rgba(28, 185, 243, 0.9) 149.71%)",
              }}
            >
              <Typography typography="h5" color="white">
                เกี่ยวกับหลักสูตร
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                color="black.main"
                dangerouslySetInnerHTML={{
                  __html: course?.description,
                }}
              />
            </AccordionDetails>
          </Accordion>

          <Box display="flex" flexDirection="column" gap="8px">
            <Typography align="center" typography="h4" color="black.main">
              รายการเอกสารดาวน์โหลด
            </Typography>

            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                borderRadius: 0,
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
              }}
            >
              <Table
                sx={{ minWidth: 300 }}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        typography: "body1",
                        fontWeight: 600,
                      }}
                    >
                      ลำดับ
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        typography: "body1",
                        fontWeight: 600,
                      }}
                    >
                      ชื่อไฟล์
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        typography: "body1",
                        fontWeight: 600,
                      }}
                    >
                      ดาวน์โหลด
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      1
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {course?.title}
                      {".pdf"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {course && (
                        <Box
                          component={RouterLink}
                          to={course?.course_url || ""}
                          target="_blank"
                          download
                        >
                          <DownloadIcon sx={{ color: "black.main" }} />
                        </Box>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Container>

      <Modal
        keepMounted
        open={openLessonNotDone}
        onClose={handleLessonNotDoneClose}
      >
        <Box sx={modalStyle} width="340px">
          <LessonNotDoneModal course={course} />
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openWaitingForAnswer}
        onClose={handleWaitingForAnswerClose}
      >
        <Box sx={modalStyle} width="340px">
          <WaitingForAnswerModal
            course={course}
            handleClose={handleWaitingForAnswerClose}
          />
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openAssessmentSuccess}
        onClose={handleAssessmentSuccessClose}
      >
        <Box sx={modalStyle} width="340px">
          <AssessmentSuccessModal
            course={course}
            handleClose={handleAssessmentSuccessClose}
          />
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openAssessmentFail}
        onClose={handleAssessmentFailClose}
      >
        <Box sx={modalStyle} width="340px">
          <AssessmentFailModal
            course={course}
            handleClose={handleAssessmentFailClose}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openWarnning} onClose={handleWarnningClose}>
        <Box sx={modalStyle} width="340px">
          <WarningModal
            message={messageWarnning}
            handleClose={handleWarnningClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default CourseDescriptionPage;
