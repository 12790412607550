import { Box, Grid, Fab, Typography, Container, Button } from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import dayjs from "dayjs";
import domtoimage from "dom-to-image";

import { boxStyle, containerStyle, pageStyle } from "../../../consts/styles";
import { useEffect, useRef, useState } from "react";
import { getProfile } from "../../../features/userStore/libs/profileSlice";
import { useAppSelector } from "../../../features/userStore/hook";

const CertificatePage = () => {
  const profile = useAppSelector(getProfile);
  const boxRef = useRef<any>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [courseId, setCourseId] = useState<any | null>(null);
  const [evaluatedDate, setEvaluatedDate] = useState<string | null>("");
  const [download, setDownload] = useState<boolean>(false);

  const handleDownload = () => setDownload(true);
  const handleDownloaded = () => setDownload(false);

  const handleGeneratePdf = async () => {
    handleDownload();

    const doc = new jsPDF("l", "mm", "a4");
    const boxElement = boxRef.current;

    if (boxElement) {
      setTimeout(async () => {
        const { width, height } = boxElement.getBoundingClientRect();
        const pdfWidth = width * 0.264583;
        const pdfHeight = height * 0.264583;

        const imageData = await domtoimage.toJpeg(boxElement);
        handleDownloaded();

        doc.addImage(imageData, "JPEG", 0, 0, pdfWidth, pdfHeight);
        doc.save(`certificate-${dayjs().format("YYYYMMDD-HHMM")}`);
      }, 100);
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/home");
      return;
    }
    setCourseId(location.state.courseId);
    setEvaluatedDate(location.state.evaluatedDate);
  }, [location, navigate]);

  return (
    <Box sx={pageStyle}>
      <Box maxWidth={{ xs: 450, sm: 600 }} width={1} zIndex={1}>
        <Grid container spacing={2} padding="8px">
          <Grid item xs={1}>
            <Fab
              sx={{
                background: "#0892F5",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                color: "white",
                "&:hover": { background: "#0892F5" },
              }}
              component={RouterLink}
              to="/course/description"
              state={{ courseId }}
            >
              <ArrowBackIcon />
            </Fab>
          </Grid>
          <Grid item xs={10} alignSelf="center">
            <Typography
              align="center"
              sx={{ typography: { xs: "h3", sm: "h1" } }}
              color="ktgGreen.main"
              zIndex={1}
            >
              ใบประกาศนียบัตร
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Container component="main" sx={containerStyle} maxWidth={false}>
        <Box sx={boxStyle}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" width={1}>
              <Box
                sx={{
                  backgroundImage: "url('/course/cert-dpac.png')",
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
                width={1}
                height={{ xs: 200, sm: 450 }}
                position="relative"
              >
                <Box position="absolute" top="46%" width={1}>
                  <Typography
                    align="center"
                    fontSize={{ xs: 9, sm: 20 }}
                    fontWeight={600}
                    color="common.black"
                  >
                    {profile.first_name} {profile.last_name}
                  </Typography>
                </Box>

                <Box position="absolute" top="67%" width={1}>
                  <Typography
                    align="center"
                    fontSize={{ xs: 7, sm: 14 }}
                    color="common.black"
                  >
                    ให้ไว้ ณ วันที่{" "}
                    {dayjs(evaluatedDate).locale("th").format("D MMMM")} พ.ศ.{" "}
                    {dayjs(evaluatedDate).locale("th").format("BBBB")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center">
            <Button
              color="green"
              fullWidth
              variant="contained"
              type="button"
              sx={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                width: "295px",
              }}
              startIcon={<DownloadIcon sx={{ color: "white" }} />}
              onClick={handleGeneratePdf}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, textTransform: "none" }}
                color="white"
              >
                ดาวน์โหลดใบประกาศนียบัตร
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>

      <Box
        ref={boxRef}
        sx={{
          width: "297mm",
          height: "210mm",
          position: "absolute",
          display: download ? "block" : "none",
          zIndex: -1,
        }}
        top={0}
        left={0}
      >
        <Box component="img" src="/course/cert-dpac.png" width={1} height={1} />
        <Box position="absolute" top="47%" width={1}>
          <Typography align="center" typography="h2" color="common.black">
            {profile.first_name} {profile.last_name}
          </Typography>
        </Box>
        <Box position="absolute" top="66%" width={1}>
          <Typography align="center" typography="h5" color="common.black">
            ให้ไว้ ณ วันที่ {dayjs(evaluatedDate).locale("th").format("D MMMM")}{" "}
            พ.ศ. {dayjs(evaluatedDate).locale("th").format("BBBB")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CertificatePage;
